<template>
    <div class="flex justify-between items-start">
        <div class="w-full mr-2">
            <slot />
        </div>

        <div class="relative flex justify-end items-center" v-click-outside="closeDropdown">
            <div
                class="mt-2 cursor-pointer"
                @click="toggleDropdown"
            >
                <DropDownThreeDots />
            </div>

            <div
                v-if="isDropdownOpen"
                class="mx-3 origin-top-right absolute top-6 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="project-options-menu-0"
            >
                <div class="py-1" role="none">
                    <a
                        @click.prevent="$emit('delete')"
                        href="#"
                        class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                    >
                        {{ $t('global.buttons.delete') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';
import ClickOutside from 'vue-click-outside';

export default {
    name: 'Item',
    directives: {
        ClickOutside
    },
    components: { DropDownThreeDots },
    data() {
        return {
            isDropdownOpen: false
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        }
    }
};
</script>

<style scoped>

</style>
