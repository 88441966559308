<template>
    <div>
        <AddNewButton
            :name="$t('global.buttons.add_new')"
            @clickAction="count++"
        />

        <Item
            v-for="i in count"
            @delete="deleteItem"
        >
            <slot />
        </Item>
    </div>
</template>

<script>
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';
import Item from './Item';

export default {
    name: 'MultiBlocks',
    components: { Item, AddNewButton },
    data() {
        return {
            count: 0
        };
    },
    methods: {
        deleteItem() {
            this.count--;
        }
    }
};
</script>

<style scoped>

</style>
