<template>
    <div v-if="!loading" class="mx-auto p-6 xl:max-w-full xl:grid xl:grid-cols-3 border-t border-b">
        <div class="xl:col-span-2 mb-8 xl:pr-8 xl:border-r xl:border-gray-200">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-2">
                    <div v-if="!editable" class="fieldTitle">
                        <MainFieldTitle :label="$t('task.fields.title')" :name="form.title"/>
                    </div>
                    <FormInput
                        v-else
                        :label="$t('task.fields.title')"
                        inputName="title"
                        :value="form.title"
                        :errorMessage="formErrors.title"
                        @handleInputChange="handleInputChange"
                    />
                </div>

                <div class="sm:col-span-2">
                    <div class="mt-2">
                        <FormTextAreaEditor
                            :label="$t('task.fields.description')"
                            :value="form.description"
                            inputName="description"
                            :disabled="!editable"
                            @handleInputChange="handleInputChange"
                        />
                    </div>
                </div>

                <div class="sm:col-span-2">
                    <div class="pt-5 border-t">
                        <h2 class="text-lg font-medium text-gray-900">{{ $t('task.fields.contacts') }}</h2>
                    </div>

                    <TaskContacts
                        v-model="form.contacts"
                        :editable="editable"
                        :formErrors="formErrors"
                    />
                </div>

                <div class="sm:col-span-2">
                    <CheckListsSection
                        :checkLists="form.checklists"
                        @handleDeleteCheckListItem="handleDeleteCheckListItem"
                        @handleDeleteCheckList="handleDeleteCheckList"
                        @handleCreateNewCheckListItem="handleCreateNewCheckListItem"
                        @handleUpdateCheckList="handleUpdateCheckList"
                        @handleUpdateCheckListItem="handleUpdateCheckListItem"
                        @handleEditCheckListItemStatus="handleEditCheckListItemStatus"
                    />
                </div>
                <div class="sm:col-span-2 sm:hidden md:hidden lg:block">
                    <CommentBox
                        :accountAvatar="account.avatar"
                        :authorName="account.name"
                        :ownerId="form.id"
                        :comments="form.comments"
                        :prefix="prefix"
                    />
                </div>
            </div>
            <div v-show="false" class="w-full flex justify-start">
                <GoogleLogin
                    :params="params"
                    class="w-full cursor-pointer text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    :onCurrentUser="updateCurrentUser">{{ $t('global.buttons.syncEvents') }}
                </GoogleLogin>
            </div>
        </div>
        <aside class="xl:pl-8">
            <RightAside
                :form="form"
                :editable="editable"
                @handleInputFieldChange="handleInputFieldChange"
                @handleAddAttachments="handleAddAttachments"
                @deleteAttachmentFile="deleteAttachmentFile"
                @handleAddNewCheckList="handleAddNewCheckList"
                @handleAddNewLink="handleAddNewLink"
                @handleEditLink="handleEditLink"
                @handleDeleteLink="handleDeleteLink"
                @handleChangeAssigneeMembers="handleChangeAssigneeMembers"
                @handleAddGoogleAttachments="handleAddGoogleAttachments"
                @deleteGoogleAttachmentFile="deleteGoogleAttachmentFile"
                @handleChangeAssigneeLabels="handleChangeAssigneeLabels"
            />
        </aside>
        <div class="sm:col-span-2 sm:block md:block lg:hidden">
            <CommentBox
                :accountAvatar="account.avatar"
                :authorName="account.name"
                :ownerId="form.id"
                :comments="form.comments"
                :prefix="prefix"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea';
import RightAside from '@/components/Task/RightAside/RightAside';
import { EventBus } from '@/eventBus/event-bus';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import { Attachments } from '@/mixins/Attachments';
import { RequestTaskForm } from '@/mixins/Task/RequestTaskForm';
import { withTaskCheckList } from '@/mixins/Task/withTaskCheckList';
import CheckListsSection from '@/components/CheckList/CheckListsSection/CheckLists/CheckLists';
import GoogleLogin from 'vue-google-login';
import FormTextAreaEditor from '@/components/Main/Form/FormTextArea/FormTextAreaEditor';
import MultiBlocks from '@/components/Main/MultiBlocks/MultiBlocks';
import TaskContacts from '@/components/TaskContacts/TaskContacts';

export default {
    name: 'Task',
    mixins: [Attachments, RequestTaskForm, withTaskCheckList],
    components: {
        TaskContacts,
        MultiBlocks,
        CheckListsSection,
        CommentBox,
        RightAside,
        FormTextArea,
        MainFieldTitle,
        FormInput,
        GoogleLogin,
        FormTextAreaEditor
    },
    data() {
        return {
            loading: true,
            editable: false,
            pageUpdateAction: true,
            prefix: 'task',
            form: {
                id: '',
                title: '',
                description: '',
                listing_id: '',
                customer_id: '',
                customer_apartment_id: '',
                member_id: '',
                contacts: [],
                assigneeId: '',
                checklists: [],
                due: '',
                files: [],
                google_files: [],
                members: [],
                comments: [],
                listings: [],
                createdAt: '',
                invoices: [],
                labels: []
            },
            formErrors: {},
            params: {
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                scope: process.env.VUE_APP_GOOGLE_SCOPE,
                prompt: 'select_account',
                include_granted_scopes: true
            }
        };
    },
    computed: {
        ...mapGetters({
          account: 'account',
          task: 'task',
          updateTaskSuccess: 'updateTaskSuccess',
          taskResponseMessage: 'taskResponseMessage',
          getBoardListings: 'getBoard'
        })
    },
    watch: {
        updateTaskSuccess() {
            this.editable = false;
            this.setBreadCrumbItem(this.form.title);
            EventBus.$emit('formSubmitted', true);
            this.taskToastMessage(this.taskResponseMessage);
        },
        updateTaskError() {
          this.editable = true;
        },
        task(data) {
            if (Object.keys(data).length) {
                this.handleSetBreadCrumbItems({ index: 1, name: data.title, to: '' });
                this.loading = false;
                this.form = { ...data };
                this.setBreadCrumbItem(data.title);
            }
        },
        getBoardListings(newValue) {
          // this.handleSetBreadCrumbItem(newValue.title);
          this.handleSetBreadCrumbItems({ index: 0, name: newValue.title, to: '/board/' + this.$route.params.id });
          this.boardData = newValue;
        }
    },
    mounted() {
        const { taskId } = this.$route.params;
        this.handleGetTask(taskId);
        EventBus.$on('handleEdit', editable => {
            this.editable = editable;
            this.form = { ...this.form };
            this.formErrors = {};
        });
        EventBus.$on('handleSave', () => this.updateTask());

        this.handleSetBreadCrumbItems({ index: 0, hidden: true });
        this.handleSetBreadCrumbItems({ index: 1, hidden: true });
        this.handleSetBreadCrumbItems({ index: 2, hidden: true });
        this.handleGetBoard(this.$route.params.id);
    },
    methods: {
        ...mapActions({
            handleGetTask: 'fetchTask',
            handleSetBreadCrumbItem: 'setBreadCrumbItem',
            handleAttachMember: 'attachMember',
            handleDetachMember: 'detachMember',
            handleCreateAttachmentFile: 'createAttachmentFile',
            handleAttachLabel: 'fetchAttachLabel',
            handleDetachLabel: 'fetchDetachLabel',
            handleSetBreadCrumbItems: 'setBreadCrumbItems',
            handleResetBreadCrumbItems: 'resetBreadCrumbItems',
            handleGetBoard: 'fetchBoard'
        }),
        updateCurrentUser(googleUser) {
            const accessToken = googleUser.getAuthResponse().access_token;
            localStorage.accessToken = accessToken;
        },
        setBreadCrumbItem(title) {
            this.handleSetBreadCrumbItem(title);
        },
        handleChangeAssigneeMembers({ user, isAssigned }) {
            const data = { id: this.form.id, form: { member_id: user.id } };
            if (!isAssigned) {
                this.attachMember(data);
                this.form.members.unshift(user);
            } else {
                this.detachMember(data);
                this.form.members = this.form.members.filter(member => member.id !== user.id);
            }
        },
        attachMember(data) {
            this.handleAttachMember({ ...data });
        },
        detachMember(data) {
            this.handleDetachMember({ ...data });
        },
        handleChangeAssigneeLabels({ label, isAssigned }) {
            const data = { id: this.form.id, form: { label_id: label.id } };
            !isAssigned ? this.attachLabel(data, label) : this.detachLabel(data, label.id);
        },
        attachLabel(data, label) {
            this.form.labels.unshift(label);
            this.handleAttachLabel({ ...data });
        },
        detachLabel(data, labelId) {
            this.form.labels = this.form.labels.filter(label => label.id !== labelId);
            this.handleDetachLabel({ ...data });
        }
    },
    destroyed() {
        this.handleResetBreadCrumbItems();
        EventBus.$off('handleSave');
        this.handleSetBreadCrumbItem();
        this.$toast.clear();
        this.$destroy();
    }
};
</script>

<style scoped>
ol {
  @apply  list-disc list-inside
}
ul {
  @apply  list-disc list-inside
}
</style>
